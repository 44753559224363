@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic; }
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic; }
body {
  font-family: "Degular", "Poppins", sans-serif;
  color: #0A0A1E;
  background-color: #f5f1ed; }

.page-padding {
  padding: 32px 150px; }

header.header-default {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 50vh;
  padding: 32px 150px;
  background-image: url("../medias/backgrounds/gradient_fluid_background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white; }
  header.header-default::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgba(10, 10, 30, 0.7); }
  header.header-default.header-min {
    height: 5rem;
    padding: 0; }

.page {
  background-color: white;
  border: 1px solid #0A0A1E;
  margin-left: 150px;
  margin-right: 150px; }

.btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 32px;
  border-radius: 0;
  font-weight: 500;
  transition: all 0.25s ease;
  text-decoration: none;
  cursor: pointer;
  font-family: "Degular", "Poppins", sans-serif;
  border: 1px solid #0A0A1E;
  font-size: 16px; }
  .btn-primary {
    background-color: #1C54E3;
    border-color: #1C54E3;
    color: white; }
    .btn-primary:hover {
      background-color: #0A0A1E;
      border-color: #0A0A1E; }
  .btn-secondary {
    background-color: transparent;
    border-color: #0733A3;
    color: #0733A3; }
    .btn-secondary:hover {
      background-color: #0733A3;
      border-color: #0733A3;
      color: white; }
  .btn-light {
    background-color: white;
    color: #0A0A1E; }
    .btn-light:hover {
      opacity: 0.8; }

h1 {
  font-size: 48px;
  font-weight: 700; }

h2 {
  font-size: 32px;
  font-weight: 700; }

h3 {
  font-weight: 600; }

p {
  font-size: 16px;
  font-weight: 400; }

.lettrine {
  float: left;
  font-size: 4em;
  line-height: 80%;
  margin: -0.1em 0px;
  padding-right: 0.1em; }

.list {
  list-style-type: none;
  padding-left: 0; }

.cta {
  position: relative;
  padding: 80px 150px;
  background-image: url("../medias/backgrounds/cta.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #0A0A1E;
  color: white;
  margin-top: 8rem; }
  .cta h2 {
    font-weight: 800;
    font-size: 48px; }
  .cta p {
    font-size: 18px; }
  .cta:not(.cta-full-width) {
    margin-left: 150px;
    margin-right: 150px; }
  .cta > div:first-child {
    padding-right: 200px; }
  .cta .character-container {
    position: absolute;
    bottom: 0; }
    .cta .character-container.character-phone {
      right: 150px; }
    .cta .character-container.character-playing-game {
      right: 0; }
      .cta .character-container.character-playing-game img {
        height: 400px; }

.card-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #0A0A1E;
  padding: 16px;
  width: 200px;
  height: 200px;
  text-align: center; }
  .card-square:hover {
    transform: translate(-10px, -10px);
    box-shadow: 10px 10px 0px #0A0A1E; }
  .card-square > svg {
    height: 32px;
    width: 32px;
    margin-bottom: 1rem; }
    .card-square > svg path {
      stroke: #0A0A1E; }
  .card-square > p {
    margin: 0; }

.citation {
  position: relative;
  padding: 24px 48px;
  background-image: url("../medias/el_graphique/double-quotes-citation-background.svg"), url("../medias/el_graphique/double-quotes-citation.svg");
  background-size: 80px, 120px;
  background-position: top 24px right 24px;
  background-repeat: repeat, no-repeat;
  background-color: rgba(10, 10, 30, 0.025);
  border-left: 1px solid #0A0A1E; }
  .citation p {
    margin: 0;
    opacity: 0.8; }
  .citation .citation-author {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .citation .citation-author > div:first-child img {
      height: 32px;
      width: 32px;
      object-fit: cover;
      object-position: center;
      border-radius: 100rem;
      margin-right: 1rem; }
    .citation .citation-author > div:last-child p {
      font-size: 14px;
      font-weight: 600;
      opacity: 0.6; }

.modal-content {
  border: none;
  border-radius: 0; }

.modal-header, .modal-body, .modal-footer {
  padding: 24px 48px; }

.modal-header {
  border-bottom-color: #0A0A1E; }

.modal-footer {
  border-top-color: #0A0A1E; }

.accordion, .accordion-item {
  border-color: #0A0A1E; }

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-item:last-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 10, 30, 0.25); }

.accordion-button:not(.collapsed) {
  background-color: rgba(10, 10, 30, 0.05); }

.row {
  display: flex;
  flex-direction: row;
  gap: 24px; }

.mb-1 {
  margin-bottom: 1rem; }

.mt-1 {
  margin-top: 1rem; }

.ms-1 {
  margin-left: 1rem; }

.me-1 {
  margin-right: 1rem; }

.mb-2 {
  margin-bottom: 2rem; }

.mt-2 {
  margin-top: 2rem; }

.ms-2 {
  margin-left: 2rem; }

.me-2 {
  margin-right: 2rem; }

.mb-3 {
  margin-bottom: 3rem; }

.mt-3 {
  margin-top: 3rem; }

.ms-3 {
  margin-left: 3rem; }

.me-3 {
  margin-right: 3rem; }

.mb-4 {
  margin-bottom: 4rem; }

.mt-4 {
  margin-top: 4rem; }

.ms-4 {
  margin-left: 4rem; }

.me-4 {
  margin-right: 4rem; }

.mb-5 {
  margin-bottom: 5rem; }

.mt-5 {
  margin-top: 5rem; }

.ms-5 {
  margin-left: 5rem; }

.me-5 {
  margin-right: 5rem; }

.mb-6 {
  margin-bottom: 6rem; }

.mt-6 {
  margin-top: 6rem; }

.ms-6 {
  margin-left: 6rem; }

.me-6 {
  margin-right: 6rem; }

.mb-7 {
  margin-bottom: 7rem; }

.mt-7 {
  margin-top: 7rem; }

.ms-7 {
  margin-left: 7rem; }

.me-7 {
  margin-right: 7rem; }

.mb-8 {
  margin-bottom: 8rem; }

.mt-8 {
  margin-top: 8rem; }

.ms-8 {
  margin-left: 8rem; }

.me-8 {
  margin-right: 8rem; }

.mb-9 {
  margin-bottom: 9rem; }

.mt-9 {
  margin-top: 9rem; }

.ms-9 {
  margin-left: 9rem; }

.me-9 {
  margin-right: 9rem; }

.mb-10 {
  margin-bottom: 10rem; }

.mt-10 {
  margin-top: 10rem; }

.ms-10 {
  margin-left: 10rem; }

.me-10 {
  margin-right: 10rem; }

@media screen and (max-width: 1048px) {
  .page-padding {
    padding: 24px 24px; }

  header {
    padding: 24px 24px; }

  .page {
    margin-left: 0;
    margin-right: 0; }

  .cta {
    padding: 80px 24px;
    background-size: 250px;
    background-position: bottom right; }
    .cta > div:first-child {
      padding-right: 150px; }
    .cta .character-container {
      right: 0 !important; }
    .cta:not(.cta-full-width) {
      margin-left: 0;
      margin-right: 0; } }
@media screen and (max-width: 375px) {
  .cta > div:first-child {
    padding-right: 0; }
  .cta .character-container {
    display: none; } }
nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  color: white;
  transition: all 0.25s ease; }

.nav-infos {
  position: relative;
  background-color: #0A0A1E;
  padding: 8px 24px;
  height: 24px;
  overflow: hidden; }
  .nav-infos::before, .nav-infos::after {
    content: "";
    z-index: 100;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    background: #0a0a1e; }
  .nav-infos::before {
    left: 0;
    background: linear-gradient(90deg, #0a0a1e 0%, rgba(10, 10, 30, 0) 100%); }
  .nav-infos::after {
    right: 0;
    background: linear-gradient(-90deg, #0a0a1e 0%, rgba(10, 10, 30, 0) 100%); }
  .nav-infos p {
    padding: 0;
    margin: 0;
    color: white;
    animation: scrollLeft 20s linear infinite;
    white-space: nowrap; }
    .nav-infos p.visible {
      opacity: 1;
      transform: translateX(100%); }
    .nav-infos p.hidden {
      display: none;
      opacity: 0;
      transform: translateX(-100%); }

@keyframes scrollLeft {
  from {
    transform: translate(100%); }
  to {
    transform: translateX(-100%); } }
.nav-container {
  display: flex;
  flex-direction: row;
  height: 5rem;
  background-color: white;
  padding: 0 150px;
  border-bottom: 1px solid #0A0A1E; }
  .nav-container > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .nav-container > div > div:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px; }

@media screen and (max-width: 1048px) {
  .nav-container {
    padding: 0 24px; } }
.nav-toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center; }
  .nav-toggle-container .nav-toggle {
    display: flex;
    flex-direction: column;
    gap: 6px;
    background-color: transparent;
    border: none;
    cursor: pointer; }
    .nav-toggle-container .nav-toggle > div {
      height: 1px;
      width: 25px;
      background-color: #0A0A1E; }

.nav-logo {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .nav-logo a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none; }
  .nav-logo img {
    height: 40px; }

.nav-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin: 0;
  padding: 0;
  height: 100%; }
  .nav-links-container a, .nav-links-container li {
    height: 100%; }
  .nav-links-container li {
    list-style-type: none; }
  .nav-links-container a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #0A0A1E;
    text-decoration: none;
    transition: all 0.25s ease; }

.nav-bigmenu-container {
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f1ed;
  display: flex;
  flex-direction: column;
  padding: 32px 150px; }
  .nav-bigmenu-container h2 {
    color: #0A0A1E;
    margin-bottom: 16px;
    text-transform: uppercase;
    font-size: 24px; }
  .nav-bigmenu-container ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    list-style-type: none;
    padding: 0;
    margin: 0; }

nav:not(.nav-show) .nav-bigmenu-container {
  display: none; }

.bigmenu-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .bigmenu-header #bigmenu-close {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
    height: 30px;
    width: 30px;
    padding: 0; }
    .bigmenu-header #bigmenu-close > div {
      height: 1px;
      width: 30px;
      background-color: #0A0A1E;
      transform-origin: center;
      transition: all 0.25s ease; }
      .bigmenu-header #bigmenu-close > div:first-child {
        transform: rotate(-45deg); }
      .bigmenu-header #bigmenu-close > div:last-child {
        transform: rotate(45deg); }
    .bigmenu-header #bigmenu-close:hover > div:first-child {
      transform: rotate(-30deg); }
    .bigmenu-header #bigmenu-close:hover > div:last-child {
      transform: rotate(30deg); }

.bigmenu-col-container {
  display: flex;
  flex-direction: row;
  gap: 32px; }

.bigmenu-col {
  display: flex;
  flex-direction: column;
  gap: 8px; }

.nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border: 1px solid #0A0A1E;
  padding: 8px;
  text-decoration: none;
  transition: all 0.25s ease; }
  .nav-item > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(10, 10, 30, 0.05);
    height: 52px;
    width: 52px; }
    .nav-item > div:first-child svg {
      height: 28px;
      width: 28px; }
      .nav-item > div:first-child svg path {
        stroke: #0A0A1E; }
  .nav-item > div:last-child {
    display: flex;
    flex-direction: column;
    padding-right: 16px; }
    .nav-item > div:last-child p {
      margin: 0; }
      .nav-item > div:last-child p.item-title {
        font-weight: bold;
        color: #0A0A1E;
        font-size: 18px; }
      .nav-item > div:last-child p.item-description {
        color: rgba(10, 10, 30, 0.6);
        font-size: 14px; }

@media screen and (max-width: 918px) {
  .bigmenu-col-container {
    flex-direction: column; } }
@media screen and (max-width: 768px) {
  .nav-bigmenu-container {
    padding: 24px 24px; } }
@media screen and (max-width: 562px) {
  .nav-container > div:first-child > div:last-child {
    display: none; } }
.form-label {
  display: block;
  margin-bottom: 4px; }

.form-help {
  font-size: 14px;
  opacity: 0.8;
  margin-top: 4px; }

.form-radio {
  display: none; }
  .form-radio + label {
    position: relative;
    padding-left: 32px;
    cursor: pointer; }
    .form-radio + label::before, .form-radio + label::after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100rem; }
    .form-radio + label::before {
      height: 14px;
      width: 14px;
      background-color: rgba(10, 10, 30, 0.1);
      transform: translate(25%, -50%); }
    .form-radio + label::after {
      height: 20px;
      width: 20px;
      border: 1px solid #0A0A1E; }
  .form-radio:checked + label::before {
    background-color: #0A0A1E; }

.form-control, .form-select {
  display: block;
  width: calc(100% - 64px);
  font-family: "Degular", "Poppins", sans-serif;
  font-size: 16px;
  border: 1px solid #0A0A1E;
  border-radius: 0;
  outline: none; }
  .form-control[multiple], .form-select[multiple] {
    height: 250px; }
  .form-control:focus, .form-select:focus {
    box-shadow: 0 0 0 0.25rem rgba(10, 10, 30, 0.1);
    border-color: rgba(10, 10, 30, 0.8); }

.form-control:not(textarea) {
  padding: 12px 32px; }

textarea.form-control {
  padding: 12px;
  width: calc(100% - 24px);
  resize: vertical; }

.required::after {
  content: "*";
  color: red;
  right: -10px; }

.form-choices {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .form-choices > li:not(:last-child) {
    margin-right: 32px; }
  .form-choices input {
    display: none; }
    .form-choices input:checked + label {
      background-color: #0A0A1E;
      color: white; }
    .form-choices input:not(:checked) + label {
      background-color: rgba(10, 10, 30, 0.05); }
  .form-choices label {
    padding: 12px 24px;
    cursor: pointer; }

.form-toggle:not(.visible) {
  display: none; }

.form-row {
  display: flex;
  flex-direction: row;
  gap: 24px; }
  .form-row > div {
    flex: 1; }

@media screen and (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0; } }
footer {
  background-color: #0A0A1E;
  color: white;
  display: flex;
  flex-direction: column; }
  footer a {
    color: white;
    text-decoration: none; }
  footer .socials-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 8px;
    margin-top: 1rem; }
    footer .socials-container > a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 16px;
      align-items: center;
      height: 32px;
      width: 32px;
      border-radius: 100rem;
      border: 1px solid rgba(255, 255, 255, 0.25);
      color: white;
      text-decoration: none;
      transition: all 0.25s ease; }
      footer .socials-container > a svg {
        height: 16px;
        width: 16px; }
        footer .socials-container > a svg path {
          stroke: white;
          fill: white; }
      footer .socials-container > a:hover {
        background-color: white;
        border-color: white; }
        footer .socials-container > a:hover svg path {
          stroke: #0A0A1E;
          fill: #0A0A1E; }
  footer > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding: 32px 150px; }
    footer > div:first-child > div {
      flex: 1; }
    footer > div:first-child > div:first-child > div:first-child > img.footer-logo {
      height: 30px;
      margin-bottom: 1rem; }
    footer > div:first-child > div:first-child > div:first-child > p {
      margin: 0; }
      footer > div:first-child > div:first-child > div:first-child > p:nth-child(2) {
        font-weight: 600; }
      footer > div:first-child > div:first-child > div:first-child > p:not(:nth-child(2)) {
        font-size: 12px; }
  footer > div:last-child {
    background-color: #1C54E3;
    color: white;
    padding: 16px 150px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    footer > div:last-child p {
      font-size: 12px;
      margin: 0; }
      footer > div:last-child p a {
        color: white; }
  footer .plan-container {
    display: flex;
    flex-direction: row;
    gap: 48px; }
    footer .plan-container h2 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0.3rem;
      margin-top: 0;
      text-transform: uppercase; }
    footer .plan-container ul {
      list-style-type: none;
      padding: 0;
      margin: 0; }

@media screen and (max-width: 768px) {
  footer > div:first-child {
    flex-direction: column;
    padding: 24px 24px; }
    footer > div:first-child > div:nth-child(2) {
      margin-top: 2.5rem; }
  footer > div:last-child {
    padding: 16px 24px; } }
@media screen and (max-width: 600px) {
  footer .plan-container {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2.5rem; } }
table {
  width: 100%; }
  table td, table th {
    padding: 12px 24px;
    border: 1px solid #0A0A1E; }
  table thead th {
    background-color: #0A0A1E;
    color: white; }
  table a {
    color: #0A0A1E; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; } }
@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; } }
@media screen and (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; } }
@media screen and (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }
.realisation-card {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  min-height: 500px;
  max-height: 700px;
  border: 1px solid #0A0A1E;
  padding: 12px;
  background-color: white; }
  .realisation-card:hover {
    transform: translateY(-10px);
    box-shadow: 10px 10px 0px #0A0A1E; }
  .realisation-card > div:first-child {
    margin-bottom: 1.5rem; }
    .realisation-card > div:first-child img {
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: center; }
  .realisation-card > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 12px 24px; }
    .realisation-card > div:last-child > div:first-child h3, .realisation-card > div:last-child > div:first-child p {
      margin: 0; }
    .realisation-card > div:last-child a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: #0A0A1E;
      text-decoration: none; }
      .realisation-card > div:last-child a.realisation-readmore {
        font-weight: bold; }
      .realisation-card > div:last-child a svg path {
        stroke: #0A0A1E;
        stroke-width: 2.5px; }

#homepage header {
  position: relative;
  z-index: 10;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: #0A0A1E;
  color: white;
  padding: 0 150px;
  background-image: url("../medias/backgrounds/gradient_fluid_background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  #homepage header::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgba(10, 10, 30, 0.7); }
  #homepage header > div:first-child {
    border: 1px solid white;
    padding: 32px;
    max-width: 50%; }
  #homepage header h2 {
    font-weight: 200;
    text-transform: uppercase;
    font-size: 48px;
    margin: 0; }

#homepage-presentation {
  padding: 32px 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  #homepage-presentation > div:first-child {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start; }
  #homepage-presentation > div:last-child {
    width: 30%; }
    #homepage-presentation > div:last-child img {
      width: 100%; }

#homepage-services {
  position: relative;
  z-index: 20;
  margin-left: 150px;
  margin-right: 150px;
  background-color: white;
  margin-top: -5rem;
  padding: 32px 64px;
  border: 1px solid #0A0A1E; }
  #homepage-services > div:first-child {
    margin-bottom: 2rem; }
  #homepage-services > div:last-child ul {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none; }
    #homepage-services > div:last-child ul > li {
      position: relative;
      margin: 0;
      border: 1px solid #0A0A1E;
      padding: 16px 24px;
      background-color: white;
      flex: 1; }
      #homepage-services > div:last-child ul > li:not(:last-child) {
        margin-right: 16px; }
      #homepage-services > div:last-child ul > li:not(:first-child) {
        margin-left: 16px; }
      #homepage-services > div:last-child ul > li a {
        text-decoration: none;
        color: #0A0A1E;
        transition: all 0.25s ease; }
        #homepage-services > div:last-child ul > li a h3, #homepage-services > div:last-child ul > li a p {
          margin: 0; }
        #homepage-services > div:last-child ul > li a > p:last-child {
          margin-top: 1rem;
          font-weight: 400; }
          #homepage-services > div:last-child ul > li a > p:last-child svg path {
            stroke: #0A0A1E; }
      #homepage-services > div:last-child ul > li:hover {
        background-color: white;
        transform: translate(-10px, -10px);
        box-shadow: 10px 10px 0px #0A0A1E; }
        #homepage-services > div:last-child ul > li:hover a > p:last-child svg {
          transform: translateX(10px); }

#last-projects {
  padding: 32px 150px; }
  #last-projects > div:first-child {
    margin-bottom: 2rem; }
  #last-projects > div:last-child {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4rem; }

#homepage-articles {
  padding: 32px 150px; }
  #homepage-articles > div:first-child {
    margin-bottom: 2rem; }
  #homepage-articles > div:nth-child(2) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px; }
    #homepage-articles > div:nth-child(2) > div {
      position: relative;
      z-index: 10;
      overflow: hidden;
      width: 100%;
      min-height: 500px;
      max-height: 600px;
      border: 1px solid #0A0A1E;
      padding: 12px;
      background-color: white; }
      #homepage-articles > div:nth-child(2) > div:hover {
        transform: translateY(-10px);
        box-shadow: 10px 10px 0 #0A0A1E; }
      #homepage-articles > div:nth-child(2) > div > div:first-child {
        margin-bottom: 1.5rem; }
        #homepage-articles > div:nth-child(2) > div > div:first-child img {
          height: 300px;
          width: 100%;
          object-fit: cover;
          object-position: center; }
      #homepage-articles > div:nth-child(2) > div > div:last-child {
        display: flex;
        flex-direction: column;
        padding: 12px 24px; }
        #homepage-articles > div:nth-child(2) > div > div:last-child h3 {
          margin: 0; }
        #homepage-articles > div:nth-child(2) > div > div:last-child a {
          color: #0A0A1E;
          text-decoration: none;
          font-weight: bold; }
          #homepage-articles > div:nth-child(2) > div > div:last-child a svg path {
            stroke: #0A0A1E;
            stroke-width: 2.5px; }

@media screen and (max-width: 1145px) {
  #last-projects > div:last-child {
    grid-template-columns: repeat(2, 1fr); }
    #last-projects > div:last-child > div {
      width: 100%; }

  #homepage-articles > div:nth-child(2) {
    grid-template-columns: repeat(2, 1fr); } }
@media screen and (max-width: 930px) {
  #homepage header {
    padding-left: 24px;
    padding-right: 24px; }
    #homepage header > div:first-child {
      max-width: 100%; }
    #homepage header h2 {
      font-size: 32px; }

  #homepage-services {
    margin: 0;
    padding: 24px 24px; }
    #homepage-services > div:last-child ul {
      flex-direction: column; }
      #homepage-services > div:last-child ul > li:not(:first-child) {
        margin: 0; }
      #homepage-services > div:last-child ul > li:not(:last-child) {
        margin: 0;
        margin-bottom: 16px; }

  #homepage-presentation {
    flex-direction: column-reverse; }
    #homepage-presentation > div:first-child {
      margin-top: 4rem;
      width: 100%; } }
@media screen and (max-width: 768px) {
  #homepage-presentation {
    padding: 24px 24px; }

  #last-projects {
    padding: 24px 24px; }
    #last-projects > div:last-child {
      grid-template-columns: repeat(1, 1fr); }
      #last-projects > div:last-child > div {
        width: 100%; }
        #last-projects > div:last-child > div:not(:last-child) {
          margin-bottom: 24px; }

  #homepage-articles {
    padding: 24px 24px; }
    #homepage-articles > div:nth-child(2) {
      grid-template-columns: repeat(1, 1fr); }
      #homepage-articles > div:nth-child(2) > div {
        width: 100%; }
        #homepage-articles > div:nth-child(2) > div:not(:last-child) {
          margin-bottom: 24px; } }
#blog header {
  position: relative;
  z-index: 10;
  height: 80vh;
  padding: 32px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white;
  background-image: url("../medias/backgrounds/gradient_fluid_background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  #blog header::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgba(10, 10, 30, 0.7); }
  #blog header > div:first-child {
    border: 1px solid white;
    padding: 32px;
    max-width: 50%; }
  #blog header h2 {
    font-weight: 200;
    text-transform: uppercase;
    font-size: 48px;
    margin: 0; }

#blog-articles {
  padding: 32px 150px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem; }
  #blog-articles > div {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    overflow: hidden;
    width: 100%;
    min-height: 500px;
    max-height: 700px;
    border: 1px solid #0A0A1E;
    padding: 12px;
    background-color: white; }
    #blog-articles > div:hover {
      transform: translateY(-10px);
      box-shadow: 10px 10px 0px #0A0A1E; }
    #blog-articles > div > div:first-child {
      margin-bottom: 1.5rem; }
      #blog-articles > div > div:first-child img {
        height: 300px;
        width: 100%;
        object-fit: cover;
        object-position: center; }
    #blog-articles > div > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 12px 24px; }
      #blog-articles > div > div:last-child h3 {
        margin: 0; }
      #blog-articles > div > div:last-child a {
        color: #0A0A1E;
        text-decoration: none; }
        #blog-articles > div > div:last-child a.article-readmore {
          font-weight: bold; }
        #blog-articles > div > div:last-child a svg path {
          stroke: #0A0A1E;
          stroke-width: 2.5px; }

@media screen and (max-width: 1450px) {
  #blog-articles {
    grid-template-columns: repeat(2, 1fr); } }
@media screen and (max-width: 900px) {
  #blog-articles {
    padding: 24px 24px;
    grid-template-columns: repeat(1, 1fr); } }
#article header {
  position: relative;
  z-index: 10;
  height: 50vh;
  padding: 32px 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: white; }
  #article header::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: rgba(10, 10, 30, 0.7); }
  #article header a {
    color: white;
    text-decoration: none; }
  #article header #header-background {
    position: absolute;
    z-index: -5;
    inset: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover; }
#article article {
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 50px;
  padding: 24px 32px;
  background-color: white;
  border: 1px solid #0A0A1E; }
  #article article img {
    max-width: 100%; }
  #article article > div.paragraphe:not(:last-child) {
    margin-bottom: 50px; }

#service-introduction > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  #service-introduction > div > div:first-child {
    margin-right: 2.5rem; }
  #service-introduction > div > div:last-child img {
    height: 250px;
    width: 250px;
    object-fit: contain;
    object-position: center; }

#service-avantages ul {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0; }

.service-presentation > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between; }
  .service-presentation > div:first-child > div:last-child img {
    height: 250px;
    width: 250px;
    object-fit: contain;
    object-position: center; }

@media screen and (max-width: 878px) {
  #service-introduction > div {
    flex-direction: column; }
    #service-introduction > div > div:first-child {
      margin-right: 0; }

  #service-avantages ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem; }
    #service-avantages ul > li {
      width: 100%; } }
@media screen and (max-width: 500px) {
  #service-avantages ul {
    display: flex;
    flex-direction: column; }
    #service-avantages ul > li:not(:last-child) {
      margin-bottom: 1rem; }

  .service-presentation > div:first-child {
    flex-direction: column; } }
#realisations-container {
  padding: 32px 150px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4rem;
  grid-row-gap: 2rem; }

@media screen and (max-width: 1315px) {
  #realisations-container {
    grid-template-columns: repeat(2, 1fr); } }
@media screen and (max-width: 900px) {
  #realisations-container {
    grid-template-columns: repeat(1, 1fr); } }
@media screen and (max-width: 615px) {
  #realisations-container {
    padding: 24px 24px; } }
#profile-team-page header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
  background-color: #0A0A1E;
  padding: 5rem 150px 0; }
  #profile-team-page header .header-geometrical img {
    height: 40vh; }
  #profile-team-page header > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #profile-team-page header h2, #profile-team-page header p {
    color: white; }
  #profile-team-page header h2 {
    font-size: 72px;
    line-height: 54px;
    max-width: 26vw;
    text-transform: uppercase; }
  #profile-team-page header p {
    font-size: 18px; }
  #profile-team-page header a {
    text-decoration: none;
    color: white; }
    #profile-team-page header a svg {
      height: 24px; }
  #profile-team-page header h2, #profile-team-page header p {
    margin: 0; }
  #profile-team-page header .socials {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px; }

.portfolio-realisations h2, .portfolio-realisations p {
  margin: 0; }

@media screen and (max-width: 958px) {
  #profile-team-page header .header-geometrical {
    display: none; }
  #profile-team-page header h2 {
    font-size: 38px;
    line-height: 28px; } }
@media screen and (max-width: 768px) {
  #profile-team-page header {
    padding: 5rem 24px 0; }
    #profile-team-page header h2 {
      max-width: none; } }
#contact-page .type-professionnel .part-particulier {
  display: none; }
#contact-page .type-particulier .part-professionnel {
  display: none; }

.contact-sended-container {
  display: flex;
  flex-direction: row;
  gap: 48px; }
  .contact-sended-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .contact-sended-container > div:first-child {
    width: 20vw; }
    .contact-sended-container > div:first-child img {
      width: 90%;
      max-height: 100%;
      min-height: 50%;
      object-fit: contain;
      object-position: center; }
  .contact-sended-container > div:last-child {
    flex: 1; }
    .contact-sended-container > div:last-child > div:first-child h2 {
      margin-bottom: 8px; }
    .contact-sended-container > div:last-child > div:first-child p {
      margin-top: 0; }

@media screen and (max-width: 1360px) {
  .contact-sended-container {
    flex-direction: column; }
    .contact-sended-container > div:first-child, .contact-sended-container > div:last-child {
      flex: 1;
      width: 100%; }
    .contact-sended-container > div:first-child img {
      max-width: 250px;
      min-width: 100px;
      min-height: 100px;
      max-height: 250px; } }
.message-tracker > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: start; }
  .message-tracker > div:first-child > div {
    flex: 1; }
    .message-tracker > div:first-child > div > div:first-child {
      position: relative;
      height: 10px;
      width: 100%;
      background-color: rgba(10, 10, 30, 0.1);
      margin-bottom: 1rem; }
    .message-tracker > div:first-child > div > div:last-child p {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600; }
      .message-tracker > div:first-child > div > div:last-child p svg {
        margin-right: 8px; }
        .message-tracker > div:first-child > div > div:last-child p svg path {
          stroke: #0A0A1E; }
    .message-tracker > div:first-child > div.state-ended > div:first-child {
      background-color: #0A0A1E; }
      .message-tracker > div:first-child > div.state-ended > div:first-child::after {
        content: "";
        position: absolute;
        top: 0;
        right: -6px;
        width: 6px;
        height: 100%;
        background-color: #0A0A1E;
        clip-path: polygon(0 0, 0% 100%, 100% 50%); }
    .message-tracker > div:first-child > div:not(.state-ended) > div:last-child p {
      color: rgba(10, 10, 30, 0.5); }
      .message-tracker > div:first-child > div:not(.state-ended) > div:last-child p svg path {
        stroke: rgba(10, 10, 30, 0.5); }

.form-error {
  color: red;
  font-weight: bold; }
